import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { useShop } from "../../../context/ShopContext";
import { Currency } from "../../../helpers";
import { functionsBase } from "../../../../config/site-config";
import {
  BoxSmall,
  BoxMedium,
  BoxLarge,
  CheckMark,
} from "../../../helpers/Icons";

const ScreenBox = ({ handler }) => {
  const [loading, setLoading] = useState(null);

  const { updateCartMetadata, metadata } = useCart();
  const progress = metadata?.signUpProgress || {};
  const { boxes } = useShop();

  const boxSmall = boxes[0];
  const boxMedium = boxes[1];
  const boxLarge = boxes[2];

  const handleBoxSelection = async (box) => {
    setLoading(box.id);

    // Create a new subscription:
    // 1) if there are no cart metadata in localstorage,
    // 2) if we don't have an active subscription from previous signup,
    // 3) if customer changed the box selection (eg. from small to medium),
    // 4) if the active subscription has expired.
    // ------------------------------------------------------------------------
    // Stripe subscriptions expire after 23 hours, if we have saved one we give
    // 1 hour to the customer to complete it before we create a new one.
    // (23 - 1) * 60 * 60 = 79200: Seconds to add to the creation timestamp.
    // We multiple by 1000 to convert unix epoch seconds to milliseconds.
    if (
      metadata === undefined ||
      (metadata.clientSecret === undefined &&
        metadata.subscription === undefined) ||
      metadata.box?.id !== box.id ||
      (metadata.subscription.created + 79200) * 1000 < new Date().getTime()
    ) {
      const { subscription, clientSecret } = await fetch(
        `${functionsBase}/create-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId: metadata.customer.id,
            priceId: box.id,
          }),
        }
      ).then((res) => res.json());

      updateCartMetadata({
        clientSecret,
        subscription,
      });
    }

    updateCartMetadata({
      box,
      signUpProgress: {
        ...progress,
        box: "complete",
        delivery: "active",
      },
    });

    setLoading(null);
    if (typeof handler === "function") handler();
  };

  return (
    <section className="signup__screen position-relative py-5">
      <StaticImage
        src="../../../images/flow-veggies-top.png"
        className="signup-veggies-top"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <StaticImage
        src="../../../images/flow-veggies-bottom.png"
        className="signup-veggies-bottom"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <Container>
        <h2>Επιλέξτε τον αριθμό γευμάτων</h2>
        <p className="mb-4">
          Μπορείτε να επιλέξετε 4, 8 ή 12 γεύματα ανά συσκευασία.
        </p>
        <Row className="g-0 px-lg-5">
          {boxSmall && (
            <Col md={4}>
              <div className="box my-4 py-4 text-center rounded-3 bg-white shadow">
                <div className="box__icon">
                  <BoxSmall size={75} />
                </div>
                <h3 className="h4 mt-4">
                  <div className="h2 mb-0 text-primary">{boxSmall.meals}</div>
                  γεύματα/εβδομάδα
                </h3>
                <p className="h6">
                  διαλέγεις έως {boxSmall.productLimit} συνταγές
                </p>
                <div className="pt-4">
                  <div className="h4 text-primary">
                    <span className="price-delete">
                      <Currency
                        value={boxSmall.compareAtPrice}
                        noZeroDecimals
                      />
                    </span>
                  </div>
                  <div className="h2 py-2 bg-light">-{boxSmall.discount}%</div>
                  <div className="h4 text-primary">
                    <Currency value={boxSmall.price} noZeroDecimals />
                  </div>
                </div>
                <hr className="m-4" />
                <div className="d-grid">
                  <p className="h5 mb-4 text-primary">
                    τιμή ανά γεύμα:{" "}
                    <Currency value={boxSmall.pricePerMeal} noZeroDecimals />
                  </p>
                  <Button
                    variant="primary"
                    className="fw-bold mx-4"
                    onClick={() => handleBoxSelection(boxSmall)}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      loading === boxSmall.id ? (
                        <Spinner animation="grow" size="sm" variant="light" />
                      ) : metadata?.box?.id === boxSmall.id ? (
                        <>
                          <CheckMark size={16} /> ΕΠΙΛΕΓΜΕΝΟ
                        </>
                      ) : (
                        "ΕΠΙΛΟΓΗ"
                      )
                    }
                  </Button>
                </div>
              </div>
            </Col>
          )}
          {boxMedium && (
            <Col md={4} style={{ zIndex: "1" }}>
              <div className="box py-4 py-md-5 text-center rounded-3 bg-white shadow">
                <div className="box__icon">
                  <BoxMedium size={72} />
                </div>
                <h3 className="h4 mt-4">
                  <div className="h2 mb-0 text-primary">{boxMedium.meals}</div>
                  γεύματα/εβδομάδα
                </h3>
                <p className="h6">
                  διαλέγεις έως {boxMedium.productLimit} συνταγές
                </p>
                <div className="pt-4">
                  <div className="h4 text-primary">
                    <span className="price-delete">
                      <Currency
                        value={boxMedium.compareAtPrice}
                        noZeroDecimals
                      />
                    </span>
                  </div>
                  <div className="h2 py-2 bg-light">-{boxMedium.discount}%</div>
                  <div className="h4 text-primary">
                    <Currency value={boxMedium.price} noZeroDecimals />
                  </div>
                </div>
                <hr className="m-4" />
                <div className="d-grid">
                  <p className="h5 mb-4 text-primary">
                    τιμή ανά γεύμα:{" "}
                    <Currency value={boxMedium.pricePerMeal} noZeroDecimals />
                  </p>
                  <Button
                    variant="primary"
                    className="fw-bold mx-4"
                    onClick={() => handleBoxSelection(boxMedium)}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      loading === boxMedium.id ? (
                        <Spinner animation="grow" size="sm" variant="light" />
                      ) : metadata?.box?.id === boxMedium.id ? (
                        <>
                          <CheckMark size={16} /> ΕΠΙΛΕΓΜΕΝΟ
                        </>
                      ) : (
                        "ΕΠΙΛΟΓΗ"
                      )
                    }
                  </Button>
                </div>
              </div>
            </Col>
          )}
          {boxLarge && (
            <Col md={4}>
              <div className="my-4 py-4 text-center rounded-3 bg-white shadow">
                <div className="box__icon">
                  <BoxLarge size={80} />
                </div>
                <h3 className="h4 mt-4">
                  <div className="h2 mb-0 text-primary">{boxLarge.meals}</div>
                  γεύματα/εβδομάδα
                </h3>
                <p className="h6">
                  διαλέγεις έως {boxLarge.productLimit} συνταγές
                </p>
                <div className="pt-4">
                  <div className="h4 text-primary">
                    <span className="price-delete">
                      <Currency
                        value={boxLarge.compareAtPrice}
                        noZeroDecimals
                      />
                    </span>
                  </div>
                  <div className="h2 py-2 bg-light">-{boxLarge.discount}%</div>
                  <div className="h4 text-primary">
                    <Currency value={boxLarge.price} noZeroDecimals />
                  </div>
                </div>
                <hr className="m-4" />
                <div className="d-grid">
                  <p className="h5 mb-4 text-primary">
                    τιμή ανά γεύμα:{" "}
                    <Currency value={boxLarge.pricePerMeal} noZeroDecimals />
                  </p>
                  <Button
                    variant="primary"
                    className="fw-bold mx-4"
                    onClick={() => handleBoxSelection(boxLarge)}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      loading === boxLarge.id ? (
                        <Spinner animation="grow" size="sm" variant="light" />
                      ) : metadata?.box?.id === boxLarge.id ? (
                        <>
                          <CheckMark size={16} /> ΕΠΙΛΕΓΜΕΝΟ
                        </>
                      ) : (
                        "ΕΠΙΛΟΓΗ"
                      )
                    }
                  </Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default ScreenBox;
