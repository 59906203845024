import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import { Button, Col, Container, Form, Row } from "react-bootstrap";

import DatePicker from "../../elements/DatePicker";
import TimePicker from "../../elements/TimePicker";
import CancelationNotice from "../../elements/CancelationNotice";

const ScreenDelivery = ({ preHandler, handler }) => {
  const { metadata } = useCart();

  return (
    <section className="signup__screen position-relative d-flex align-items-center py-5">
      <StaticImage
        src="../../../images/flow-veggies-top.png"
        className="signup-veggies-top"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <StaticImage
        src="../../../images/flow-veggies-bottom.png"
        className="signup-veggies-bottom"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <StaticImage
        src="../../../images/flow-calendar.png"
        className="signup-calendar"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <Container className="mw-1000">
        <h2 className="mb-4">
          Επιλέξτε την ημερομηνία που θέλετε να παραλαμβάνετε{" "}
          <br className="d-none d-lg-block" />
          τα γεύματά σας:
        </h2>
        <Form>
          <Row>
            <Col md={6}>
              <DatePicker preHandler={preHandler} size="lg" />
            </Col>
            <Col md={6}>
              <TimePicker preHandler={preHandler} size="lg" />
            </Col>
          </Row>
        </Form>
        <hr className="bg-transparent my-4 my-md-7" />
        <CancelationNotice />
        <div className="text-center">
          <Button
            variant="primary"
            className="px-lg-6"
            onClick={handler}
            disabled={!metadata.date || !metadata.time}
          >
            ΕΠΙΒΕΒΑΙΩΣΗ
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default ScreenDelivery;
