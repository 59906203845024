import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";

import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

import ScreenEmail from "../components/blocks/signup/ScreenEmail";
import ScreenBox from "../components/blocks/signup/ScreenBox";
import ScreenDelivery from "../components/blocks/signup/ScreenDelivery";
import ScreenMenu from "../components/blocks/signup/ScreenMenu";

import { CheckMark } from "../helpers/Icons";

import "../styles/pages/signup.scss";

const Step = ({ children, status = "pending", onClick }) => {
  const pending = status === "pending";

  return (
    <button
      className={`signup__step${!pending ? " text-primary" : ""}`}
      type="button"
      disabled={pending}
      onClick={onClick}
      onKeyPress={(e) => {
        if (e.key === "Enter") onClick();
      }}
    >
      <span className={`signup__step-status ${status}`}>
        {status === "complete" && <CheckMark />}
      </span>
      {children}
    </button>
  );
};

const SignUp = ({ data }) => {
  if (!data) return null;

  const { logo, short_title: shortTitle } = data.prismicShop.data;
  const { metadata, updateCartMetadata } = useCart();
  const progress = metadata?.signUpProgress || null;

  // Get which screen is active from cart metadata
  const activeScreenMeta = progress
    ? Object.keys(progress).filter((k) => progress[k] === "active")[0]
    : "email";

  const [activeScreen, setActiveScreen] = useState(activeScreenMeta);
  const [email, setEmail] = useState(progress ? progress.email : "active");
  const [box, setBox] = useState(progress ? progress.box : "pending");
  const [delivery, setDelivery] = useState(
    progress ? progress.delivery : "pending"
  );
  const [menu, setMenu] = useState(progress ? progress.menu : "pending");

  const handleEmail = () => {
    setEmail("complete");
    setBox("active");
    setActiveScreen("box");
  };

  const handleBox = () => {
    setBox("complete");
    setDelivery("active");
    setActiveScreen("delivery");
  };

  const preHandleDelivery = () => {
    // Stripe subscriptions expire after 23 hours, if we have saved one we give
    // 1 hour to the customer to complete it before we create a new one.
    // (23 - 1) * 60 * 60 = 79200: Seconds to add to the creation timestamp.
    // We multiple by 1000 to convert unix epoch seconds to milliseconds.
    if (
      metadata?.subscription &&
      (metadata.subscription.created + 79200) * 1000 < new Date().getTime()
    ) {
      updateCartMetadata({
        box: null,
        subscription: null,
        signUpProgress: {
          ...progress,
          box: "active",
          delivery: "pending",
        },
      });
      setDelivery("pending");
      setBox("active");
      setActiveScreen("box");

      return true;
    }

    return false;
  };

  const handleDelivery = () => {
    setDelivery("complete");
    setMenu("active");
    setActiveScreen("menu");
  };

  return (
    <>
      <Helmet>
        <body className="bg-light" />
      </Helmet>
      <Layout disableHeader disableFooter>
        <article className="signup">
          {/* Header */}
          <Navbar
            as="header"
            bg="white"
            sticky="top"
            className="site-header py-3"
          >
            <Container className="justify-content-center">
              <Navbar.Brand as={Anchor} href="/">
                <GatsbyImage
                  image={logo.gatsbyImageData}
                  alt={logo.alt ? logo.alt : ""}
                />
                <span className="visually-hidden">{shortTitle}</span>
              </Navbar.Brand>
              <div className="signup__steps d-flex mx-auto">
                <Step
                  status={email}
                  onClick={() =>
                    email !== "pending" && setActiveScreen("email")
                  }
                >
                  EMAIL
                </Step>
                <Step
                  status={box}
                  onClick={() => box !== "pending" && setActiveScreen("box")}
                >
                  ΣΥΣΚΕΥΑΣΙΑ
                </Step>
                <Step
                  status={delivery}
                  onClick={() =>
                    delivery !== "pending" && setActiveScreen("delivery")
                  }
                >
                  ΠΑΡΑΔΟΣΗ
                </Step>
                <Step
                  status={menu}
                  onClick={() => menu !== "pending" && setActiveScreen("menu")}
                >
                  ΓΕΥΜΑ
                </Step>
                <Step>ΟΛΟΚΛΗΡΩΣΗ</Step>
              </div>
            </Container>
          </Navbar>

          {/* Screen: Email */}
          {activeScreen === "email" && (
            <ScreenEmail image={logo} handler={handleEmail} />
          )}

          {/* Screen: Box */}
          {activeScreen === "box" && <ScreenBox handler={handleBox} />}

          {/* Screen: Delivery */}
          {activeScreen === "delivery" && (
            <ScreenDelivery
              preHandler={preHandleDelivery}
              handler={handleDelivery}
            />
          )}

          {/* Screen Menu */}
          {activeScreen === "menu" && (
            <ScreenMenu products={data.allPrismicProduct.nodes} />
          )}
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query signUpQuery($logoWidth: Int = 200) {
    prismicShop {
      data {
        short_title
        logo {
          alt
          url
          gatsbyImageData(
            width: $logoWidth
            placeholder: BLURRED
            imgixParams: { q: 100 }
          )
          dimensions {
            height
            width
          }
        }
      }
    }
    allPrismicProduct {
      nodes {
        ...prismicCardProductFragment
      }
    }
  }
`;

export default SignUp;
