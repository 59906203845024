import React from "react";

import { Container } from "react-bootstrap";

import ProductsList from "../ProductsList";

import { nextMonday } from "../../../helpers";

const ScreenMenu = ({ products }) => (
  <section className="signup__screen py-5 py-lg-6 bg-white">
    <Container>
      <h2 className="mb-5 mb-lg-6">
        Επιλέξτε τις συνταγές για την εβδομάδα {nextMonday()}
      </h2>
      <ProductsList list={products} showPrice={false} />
    </Container>
  </section>
);

export default ScreenMenu;
