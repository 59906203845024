import React, { useState } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";

import { useCustomer } from "../../../context/CustomerContext";
import { isValidEmail } from "../../../helpers/helpers";
import { functionsBase } from "../../../../config/site-config";

const ScreenEmail = ({ image, handler }) => {
  const { metadata, updateCartMetadata } = useCart();
  const { customer, setCustomer, handleLogin } = useCustomer();
  const progress = metadata?.signUpProgress || {};

  // "No email" should be an empty string for the input to have a valid value
  const [email, setEmail] = useState(customer?.email || "");
  const [password, setPassword] = useState("");
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setError(null);
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    if (!isValidEmail(email)) {
      setError("Το email δεν είναι έγκυρο.");
      return null;
    }

    setLoading(true);

    // If we have an existing customer login and continue
    if (existingCustomer) {
      const loggedIn = await handleLogin({ email, password });

      if (loggedIn) {
        updateCartMetadata({
          customer,
          signUpProgress: {
            ...progress,
            email: "complete",
            box: "active",
          },
        });
        setLoading(false);
      } else {
        setError("Το email ή ο κωδικός είναι λάθος.");
        setLoading(false);
        return null;
      }

      // Check if there is an email in state and if its different from the
      // customers email
    } else if (!email || email !== customer?.email) {
      await fetch(`${functionsBase}/create-customer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setCustomer(res.customer);
          updateCartMetadata({
            customer: res.customer,
            signUpProgress: {
              ...progress,
              email: "complete",
              box: "active",
            },
          });
        })
        .catch((err) => {
          setError("Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξανά αργότερα.");
        })
        .finally(() => setLoading(false));

      // if customer is already logged in continue to next step
    } else {
      updateCartMetadata({
        customer,
        signUpProgress: {
          ...progress,
          email: "complete",
          box: "active",
        },
      });
    }

    if (email && typeof handler === "function") handler();
    return null;
  };

  return (
    <section className="signup__screen position-relative py-6">
      <StaticImage
        src="../../../images/flow-email-bg.jpg"
        alt=""
        className="gatsby-background-image-element bg-light"
        objectFit="cover"
        layout="fullWidth"
      />
      <Container>
        <header className="mb-5 text-center">
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt ? image.alt : ""}
            className="mb-4"
          />
          <p className="h4">
            Εισαγάγετε το email σας, επιλέξτε
            <br />
            γεύματα και σας στέλνουμε
            <br />
            υλικά και συνταγές
          </p>
        </header>
        <Form className="signup__email-form">
          <Form.Group className="mb-2" controlId="signupForm.EmailInput">
            <Form.Label className="ms-3 fw-bold">
              Ηλεκτρονική διεύθυνση
            </Form.Label>
            <Form.Control
              type="email"
              className="rounded-3 bg-transparent"
              value={email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {existingCustomer && (
            <Form.Group className="mb-2" controlId="signupForm.PasswordInput">
              <Form.Label className="ms-3 fw-bold">
                Κωδικός Πρόσβασης
              </Form.Label>
              <Form.Control
                type="password"
                className="rounded-3 bg-transparent"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          )}
          {(!email || email !== customer?.email) && (
            <Form.Group
              className="mb-2"
              controlId="signupForm.ExistingCustomer"
            >
              <Form.Check
                type="checkbox"
                label="Έχω λογαριασμό στο Hello Chef"
                className="small"
                checked={existingCustomer}
                onChange={() => setExistingCustomer(!existingCustomer)}
              />
            </Form.Group>
          )}
          {error && <Alert variant="danger">{error}</Alert>}
          <Button
            variant="primary"
            className="w-100"
            type="button"
            onClick={handleSubmit}
            disabled={!email || loading}
          >
            {loading ? (
              <>
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="light"
                  className="me-1"
                />
                ΓΙΝΕΤΑΙ ΕΓΓΡΑΦΗ...
              </>
            ) : (
              "ΣΥΝΕΧΕΙΑ"
            )}
          </Button>
          {/* TODO: Add tos & privacy links */}
          <p className="mt-5 text-muted text-center small">
            Συνεχίζοντας, αποδέχομαι τους Όρους και
            <br />
            την Πολιτική Απορρήτου του Hello Chef.
          </p>
        </Form>
      </Container>
    </section>
  );
};

export default ScreenEmail;
